export const RHMXProductImageViewer = () => import('./rhm-mx-product-image-viewer');
export const RHMMXProductManuals = () => import("./rhm-mx-product-manuals");
export const RHMMXProductManualsContainer = () => import("./rhm-mx-product-manuals-container");
export const RHMMXProductDetailsContainer = () => import("./rhm-mx-product-details-container");
export const RHMMXProductLongDescription = () => import('./rhm-mx-product-long-description');
export const RHMMXProductOfferCard = () => import('./rhm-mx-product-offer-card');
export const RHMMXProductOfferCardsContainer = () => import('./rhm-mx-product-offer-cards-container');
export const RHMMXProductName = () => import('./rhm-mx-product-name');
export const RHMMXProductPrice = () => import('./rhm-mx-product-price');
export const RHMMXProductShortDescription = () => import('./rhm-mx-product-short-description');
export const RHMMXProductAddToCartButton = () => import('./rhm-mx-product-add-to-cart-button');
export const RHMMXProductStamps = () => import('./rhm-mx-product-stamps');
export const RHMMXDetailsBar = () => import('./rhm-mx-details-bar');
export const RHMMXProductShippingStamps = () => import('./rhm-mx-product-shipping-stamps');
export const RHMMXProductShippingContainer = () => import('./rhm-mx-product-shipping-container');
export const RHMMXProductNotifyMe = () => import('./rhm-mx-product-details-notify-me');
export const RHMMXProductSpecs = () => import('./rhm-mx-product-specs');
