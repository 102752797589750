export const RHMMXHeader = () => import('./rhm-mx-header');
export const RHMMXCardsCarouselContainer = () => import('./rhm-mx-cards-carousel-container');
export const RHMMXHeroBanner = () => import('./rhm-mx-hero-banner');
export const RHMMXBannerCarouselContainer = () => import('./rhm-mx-banner-carousel-container');
export const RHMMXStripBanner = () => import('./rhm-mx-strip-banner');
export const RHMXContainer = () => import('./rhm-mx-container');
export const RHMMXCollectionNavigationTitle = () => import('./rhm-mx-collection-navigation-title');
export const RHMMXProductRecommendationsWithBanner = () => import("./rhm-mx-product-recommendations-carousel-with-banner");
export const RHMMXFooter = () => import('./rhm-mx-footer');
export const RHMMXImageCard = () => import('./rhm-mx-cards-carousel-container/widgets/rhm-mx-image-card');
export const RHMMXCategoryBanner = () => import('./rhm-mx-category-banner');
export const RHMMXContainer = () => import('./rhm-mx-container');
export const RHMMX404 = () => import('./rhm-mx-404');
export const RHMMXNavigationBreadcrumbs = () => import ('./rhm-mx-navigation-breadcrumbs');
export const RHMMXCategoryContentContainer = () => import('./rhm-mx-category-content-container');
